var exports = {};

/**
 * Replaces a leading - with \-
 * @private
 * @param {any} value the facet value to replace
 * @returns any
 */
function escapeFacetValue(value) {
  if (typeof value !== "string") return value;
  return String(value).replace(/^-/, "\\-");
}
/**
 * Replaces a leading \- with -
 * @private
 * @param {any} value the escaped facet value
 * @returns any
 */


function unescapeFacetValue(value) {
  if (typeof value !== "string") return value;
  return value.replace(/^\\-/, "-");
}

exports = {
  escapeFacetValue: escapeFacetValue,
  unescapeFacetValue: unescapeFacetValue
};
export default exports;