import _find from "./find";
var exports = {};
var find = _find;
/**
 * Transform sort format from user friendly notation to lodash format
 * @param {string[]} sortBy array of predicate of the form "attribute:order"
 * @param {string[]} [defaults] array of predicate of the form "attribute:order"
 * @return {array.<string[]>} array containing 2 elements : attributes, orders
 */

exports = function formatSort(sortBy, defaults) {
  var defaultInstructions = (defaults || []).map(function (sort) {
    return sort.split(":");
  });
  return sortBy.reduce(function preparePredicate(out, sort) {
    var sortInstruction = sort.split(":");
    var matchingDefault = find(defaultInstructions, function (defaultInstruction) {
      return defaultInstruction[0] === sortInstruction[0];
    });

    if (sortInstruction.length > 1 || !matchingDefault) {
      out[0].push(sortInstruction[0]);
      out[1].push(sortInstruction[1]);
      return out;
    }

    out[0].push(matchingDefault[0]);
    out[1].push(matchingDefault[1]);
    return out;
  }, [[], []]);
};

export default exports;